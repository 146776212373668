<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="domainTable"
          title="코드도메인"
          tableId="domainTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="domainGrid.columns"
          :data="domainGrid.data"
          selection="single"
          rowKey="domainCd"
          :isExcelDown="false"
          @rowClick="getGrps"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addRowDomain" v-if="editable" />
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeDomain" v-if="editable" />
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveDomain" v-if="editable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="grpTable"
          title="코드그룹"
          tableId="grpTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="true"
          :columns="grpGrid.columns"
          :data="grpGrid.data"
          selection="multiple"
          :isExcelDown="false"
          rowKey="codeGrpCd"
          :editable="editable"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLADD" icon="add" @btnClicked="addRowGrp" v-if="editable && isSelectDomain" />
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeGrp" v-if="editable && isSelectDomain" />
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveGrp" v-if="editable && isSelectDomain" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">도메인 코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenGrpCd" autofocus @keyup.enter="setGrp" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat :label="$comm.getLangLabel('LBLCANCEL')" @click="cancelGrp" />
          <q-btn flat :label="$comm.getLangLabel('LBLADD')" @click="setGrp" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="promptMst" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">그룹코드</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat :label="$comm.getLangLabel('LBLCANCEL')" @click="cancelMst" />
          <q-btn flat :label="$comm.getLangLabel('LBLADD')" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'code-group',
  data() {
    return {
      prompt: false,
      promptMst: false,
      hiddenGrpCd: '',
      hiddenMstCd: '',
      searchParam: {
        srchDomainCd: null,
      },
      editable: true,
      isSelectDomain: false,
      selectedDomainCd: '',
      domainlistUrl: '',
      domainSaveUrl: '',
      domainDeleteUrl: '',
      grouplistUrl: '',
      groupSaveUrl: '',
      groupDeleteUrl: '',
      domainGrid: {
        columns: [
          {
            name: 'domainCd',
            field: 'domainCd',
            label: '도메인 코드',
            align: 'left',
            style: 'width: 30%; cursor:pointer',
            sortable: false,
          },
          {
            name: 'domainNm',
            field: 'domainNm',
            label: '도메인 명',
            required: true,
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      grpGrid: {
        columns: [
          {
            name: 'codeGrpCd',
            field: 'codeGrpCd',
            label: '그룹코드',
            align: 'left',
            style: 'width: 40%',
            sortable: false,
          },
          {
            name: 'codeGrpNm',
            field: 'codeGrpNm',
            label: '코드 그룹명',
            required: true,
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'systemFlag',
            field: 'systemFlag',
            label: '시스템전용',
            align: 'center',
            style: 'width:80px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.domainlistUrl = selectConfig.sys.code.domain.list.url;
      this.domainSaveUrl = transactionConfig.sys.code.domain.save.url;
      this.domainDeleteUrl = transactionConfig.sys.code.domain.delete.url;
      this.grouplistUrl = selectConfig.sys.code.group.list.url;
      this.groupSaveUrl = transactionConfig.sys.code.group.save.url;
      this.groupDeleteUrl = transactionConfig.sys.code.group.delete.url;
      this.getDomains();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getDomains() {
      this.$http.url = this.domainlistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.domainGrid.data = _result.data;
      },);
    },
    getGrps(row) {
      if (row.editFlag != 'C') {
        this.isSelectDomain = true;
        this.selectedDomainCd = row.domainCd;
        this.$http.url = this.$format(this.grouplistUrl, this.selectedDomainCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grpGrid.data = _result.data;
        },
        () => {
        });
      }
    },
    saveDomain() {
      if (this.$comm.validTable(this.domainGrid.columns, this.domainGrid.data)) {
        let saveData = this.domainGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.domainSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getDomains();
                this.rowRemoveSelect();
                this.isSelectDomain = false;
                this.grpGrid.data = [];
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRowDomain() {
      this.rowRemoveSelect();
      this.isSelectDomain = false;
      this.grpGrid.data = [];
      this.prompt = true;
    },
    cancelGrp() {
      this.hiddenGrpCd = '';
      this.prompt = false;
    },
    setGrp() {
      this.prompt = false;
      this.domainGrid.data.splice(0, 0, {
        editFlag: 'C',
        domainCd: this.hiddenGrpCd,
        domainNm: '',
        useFlag: 'Y',
      })
      this.hiddenGrpCd = '';
    },
    removeDomain() {
      let selectData = this.$refs['domainTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.domainDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getDomains();
              this.rowRemoveSelect();
              this.isSelectDomain = false;
              this.grpGrid.data = [];
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveGrp() {
      if (this.$comm.validTable(this.grpGrid.columns, this.grpGrid.data)) {
        let saveData = this.grpGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.groupSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getGrps({domainCd: this.selectedDomainCd});
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRowGrp() {
      this.promptMst = true;
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.promptMst = false;
    },
    setMst() {
      this.promptMst = false;
      this.grpGrid.data.splice(0, 0, {
        editFlag: 'C',
        domainCd: this.selectedDomainCd,
        codeGrpCd: this.hiddenMstCd,
        codeGrpNm: '',
        systemFlag: 'N',
      })
      this.hiddenMstCd = '';
    },
    removeGrp() {
      let selectData = this.$refs['grpTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.groupDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
              this.getGrps({domainCd: this.selectedDomainCd});
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
